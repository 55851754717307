import React from "react";
import styled from "styled-components";
import * as colors from "../utilities/colors";

// import * as mediaQuery from "../utilities/mediaQuery";

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 1.75em;

  &.dark {
    .wordmark-container {
      &,
      use {
        fill: ${colors.session1DarkPoint};
      }
    }
  }
`;

const CircleContainer = styled.svg`
  aspect-ratio: 1/1;
  display: inline-flex;
  height: 1.75em;
  width: 1.75em;

  &,
  use {
    clip-rule: evenodd;
    fill-rule: evenodd;
    fill: ${colors.brand};
  }
`;

const WordmarkContainer = styled.svg`
  display: inline-flex;
  padding: 4px;
  width: 14em;

  &,
  use {
    fill: ${colors.lightPoint};
  }
`;

const Logo = ({ className }) => {
  return (
    <LogoContainer className={className}>
      <WordmarkContainer className="wordmark-container">
        <use
          xlinkHref={
            className === "dark" ? "#logo-wordmark-dark" : "#logo-wordmark"
          }
        />
      </WordmarkContainer>
    </LogoContainer>
  );
};

export default Logo;
