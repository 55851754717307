import React from "react";

const SVGSprite = () => (
  <svg
    style={{ display: "none", height: "0", width: "0", visibility: "hidden" }}
  >
    <symbol id="keyhole" viewBox="0 0 25.3 26.9">
      <path d="M13.3,0.2l9.9,4.2c1.2,0.5,2.1,1.6,2.1,3c-0.1,5.3-2.2,14.8-11.3,19.2c-0.9,0.4-1.9,0.4-2.8,0 C2.2,22.2,0,12.7,0,7.4c-0.1-1.4,0.8-2.5,2-3l9.9-4.2C12.1,0.1,12.4,0,12.7,0C12.9,0,13.1,0.1,13.3,0.2z M15.6,11c0-1.6-1.4-3-3-3 c-1.6,0-3,1.4-3,3c0,1.2,0.7,2.2,1.7,2.7v3.6c0,0.7,0.5,1.3,1.3,1.3c0.7,0,1.3-0.5,1.3-1.3v-3.6C14.9,13.2,15.6,12.2,15.6,11z" />
    </symbol>

    <symbol id="trophy" viewBox="0 0 30.4 27">
      <path d="M21.1,0c1.4,0,2.5,1.2,2.5,2.6c-0.1,0.3-0.1,0.5-0.1,0.8h5.6c0.7,0,1.3,0.6,1.3,1.3c0,4.9-1.8,8.3-4.2,10.6 c-2.3,2.3-5.2,3.4-7.3,4c-1.3,0.3-2.1,1.4-2.1,2.4c0,1.1,0.9,2,2,2h1.4c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7H10.1 c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7h1.4c1.1,0,2-0.9,2-2c0-1.1-0.8-2.1-2.1-2.4c-2.1-0.6-5-1.7-7.3-4 C1.7,12.9,0,9.5,0,4.6C0,4,0.5,3.4,1.3,3.4h5.5c0-0.3,0-0.5-0.1-0.8C6.7,1.2,7.9,0,9.3,0H21.1z M2.5,5.9L2.5,5.9 c0.3,3.5,1.7,5.9,3.4,7.5c1.2,1.2,2.5,2,3.8,2.5C8.5,13.9,7.5,10.7,7,5.9H2.5z M24.5,13.4c1.7-1.6,3.1-4,3.3-7.5h-4.5 c-0.5,4.8-1.5,8-2.7,10.1C21.9,15.4,23.3,14.6,24.5,13.4z" />
    </symbol>

    <symbol id="refresh" viewBox="0 0 16.8 15.8">
      <path d="M3.1,6C2.9,6.6,2.2,6.9,1.7,6.7C1.1,6.5,0.8,5.9,1,5.3c0.4-1.1,1-2.1,1.9-3c3.1-3.1,8.1-3.1,11.1,0L14.6,3V1.1 c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v4.5c0,0.6-0.5,1.1-1.1,1.1h-4.5c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H13 l-0.6-0.6c-2.2-2.2-5.8-2.2-8,0C3.8,4.5,3.4,5.2,3.1,6z M0.8,9.1c0,0,0.1,0,0.1,0C1,9,1.1,9,1.1,9h4.5c0.6,0,1.1,0.5,1.1,1.1 c0,0.6-0.5,1.1-1.1,1.1H3.8l0.6,0.6c2.2,2.2,5.8,2.2,8,0c0.6-0.6,1.1-1.3,1.3-2.1c0.2-0.6,0.9-0.9,1.4-0.7c0.6,0.2,0.9,0.8,0.7,1.4	c-0.4,1.1-1,2.1-1.9,3c-3.1,3.1-8.1,3.1-11.1,0l-0.6-0.6v1.8c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1v-4.5C0,10.1,0,10,0,10 c0-0.1,0-0.1,0-0.1c0-0.2,0.1-0.4,0.3-0.5C0.5,9.2,0.6,9.1,0.8,9.1z" />
    </symbol>

    <symbol id="logo-circle" viewBox="0 0 28 28">
      <path d="M14,28c7.7,0,14-6.3,14-14c0-7.7-6.3-14-14-14C6.3,0,0,6.3,0,14C0,21.7,6.3,28,14,28z M14,24 c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10C8.5,4,4,8.5,4,14C4,19.5,8.5,24,14,24z" />
    </symbol>

    <symbol id="logo-wordmark" viewBox="0 0 1460 340">
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M963.4,249.8c0-0.5,0.4-0.9,0.9-0.9h4.3c0.5,0,0.9,0.4,0.9,0.9v12.2h15v-12.2c0-0.5,0.4-0.9,0.9-0.9h4.2
			c0.5,0,0.9,0.4,0.9,0.9v30.4c0,0.5-0.4,0.9-0.9,0.9h-4.2c-0.5,0-0.9-0.4-0.9-0.9v-12.7h-15v12.7c0,0.5-0.4,0.9-0.9,0.9h-4.3
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
          <path
            fill="#FFFFFF"
            d="M1060.9,249.8c0-0.5,0.4-0.9,0.9-0.9h18.7c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-13.6v7.5h11.4
			c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-11.4v8.1h13.6c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-18.7
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
          <path
            fill="#FFFFFF"
            d="M1145.8,279.9l14.3-31c0.1-0.3,0.4-0.5,0.8-0.5h0.5c0.5,0,0.6,0.2,0.8,0.5l14.2,31c0.3,0.6-0.1,1.2-0.8,1.2
			h-4c-0.7,0-1-0.3-1.3-0.9l-2.2-5h-13.7l-2.3,5c-0.2,0.5-0.6,0.9-1.3,0.9h-4C1145.9,281.1,1145.6,280.5,1145.8,279.9z
			 M1165.7,270.1l-4.6-10.1h-0.1l-4.5,10.1H1165.7z"
          />
          <path
            fill="#FFFFFF"
            d="M1242.6,249.8c0-0.5,0.4-0.9,0.9-0.9h4.2c0.5,0,0.9,0.4,0.9,0.9v25.8h11.7c0.5,0,0.9,0.4,0.9,0.9v3.8
			c0,0.5-0.4,0.9-0.9,0.9h-16.8c-0.5,0-0.9-0.4-0.9-0.9L1242.6,249.8L1242.6,249.8z"
          />
          <path
            fill="#FFFFFF"
            d="M1330.4,254.4h-7c-0.5,0-0.9-0.4-0.9-0.9v-3.8c0-0.5,0.4-0.9,0.9-0.9h20.1c0.5,0,0.9,0.4,0.9,0.9v3.8
			c0,0.5-0.4,0.9-0.9,0.9h-7v25.8c0,0.5-0.4,0.9-0.9,0.9h-4.3c-0.5,0-0.9-0.4-0.9-0.9L1330.4,254.4L1330.4,254.4z"
          />
          <path
            fill="#FFFFFF"
            d="M1411.2,249.8c0-0.5,0.4-0.9,0.9-0.9h4.3c0.5,0,0.9,0.4,0.9,0.9v12.2h15v-12.2c0-0.5,0.4-0.9,0.9-0.9h4.2
			c0.5,0,0.9,0.4,0.9,0.9v30.4c0,0.5-0.4,0.9-0.9,0.9h-4.2c-0.5,0-0.9-0.4-0.9-0.9v-12.7h-15v12.7c0,0.5-0.4,0.9-0.9,0.9h-4.3
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M411.4,157.7c9.8,0,17.8,2.7,17.8,2.7c0.2-12.4-3.7-16.8-12.7-16.8s-21.7,1.9-27.3,3.7
			c-3.4,1-5.1-1.2-5.6-4.4l-2.2-12.7c-0.7-3.7,1.2-5.4,3.4-6.1c1.9-0.7,17.6-4.6,33.2-4.6c30.5,0,37.5,15.8,37.5,42.4v52.9
			c0,2.4-2.2,4.6-4.6,4.6H442c-1.7,0-2.9-0.7-4.1-3.4l-2.9-6.6c-5.6,5.1-14.6,12.4-29.8,12.4c-19.5,0-32.9-12.7-32.9-33.4
			C372.3,171.1,386.5,157.7,411.4,157.7z M411.8,201.1c7.8,0,15.4-6.6,16.8-9.5v-13.4c0,0-6.6-2.9-13.7-2.9
			c-9.5,0-15.8,5.4-15.8,13.2C399.2,195.8,404.3,201.1,411.8,201.1z"
          />
          <path
            fill="#FFFFFF"
            d="M483.5,126.5c0-2.7,2.2-4.6,4.6-4.6h9.3c2.2,0,3.4,1.2,4.1,2.9l3.4,9c3.7-3.9,15.6-14.4,34.4-14.4
			c31,0,43.4,23.2,43.4,48.5v46.8c0,2.4-2.2,4.6-4.6,4.6h-20c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.6-20.7-18.3-20.7
			c-12.7,0-20.5,8.8-22.9,15.4v53.6c0,3.4-1.5,4.6-6.1,4.6h-18c-2.4,0-4.6-2.2-4.6-4.6L483.5,126.5L483.5,126.5z"
          />
          <path
            fill="#FFFFFF"
            d="M652.5,119.4c27.6,0,50,23.4,50,51c0,28-22.4,51.4-50,51.4c-27.5,0-50-23.4-50-51.4
			C602.5,142.9,624.9,119.4,652.5,119.4z M652.5,195.5c12.9,0,23.4-11.2,23.4-25.1c0-13.7-10.5-24.6-23.4-24.6
			c-13.2,0-23.4,11-23.4,24.6C629.1,184.3,639.3,195.5,652.5,195.5z"
          />
          <path
            fill="#FFFFFF"
            d="M724.2,126.5c0-2.7,2.2-4.6,4.6-4.6h9.3c2.2,0,3.4,1.2,4.1,2.9l3.4,9c3.7-3.9,15.6-14.4,34.4-14.4
			c31,0,43.4,23.2,43.4,48.5v46.8c0,2.4-2.2,4.6-4.6,4.6h-20c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.6-20.7-18.3-20.7
			c-12.7,0-20.5,8.8-22.9,15.4v53.6c0,3.4-1.5,4.6-6.1,4.6h-18.1c-2.4,0-4.6-2.2-4.6-4.6L724.2,126.5L724.2,126.5z"
          />
          <path
            fill="#FFFFFF"
            d="M836.8,128.2c-1.5-3.2,0.5-6.3,4.1-6.3h21.9c2.2,0,3.4,1.2,4.1,2.7l24.6,55.1h0.2l24.4-55.1
			c1.2-2.4,2.9-2.7,5.8-2.7h19.5c3.9,0,5.8,3.2,4.1,6.3l-57.1,123.9c-0.7,1.5-2.2,2.9-4.1,2.9h-21c-3.7,0-5.8-3.2-4.1-6.6l17.1-35.9
			L836.8,128.2z"
          />
          <path
            fill="#FFFFFF"
            d="M962.4,126.5c0-2.7,2.2-4.6,4.6-4.6h9c2.2,0,3.7,1.2,4.1,2.7l3.4,9.3c4.1-4.6,15.4-14.4,32.7-14.4
			c13.9,0,23.7,5.4,31.7,15.1c4.1-4.4,16.8-15.1,34.4-15.1c31.2,0,42.7,23.2,42.7,48.8v46.6c0,2.4-1.9,4.6-4.6,4.6h-19.8
			c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.1-20.7-18.3-20.7c-14.1,0-20.5,10.2-20.5,10.2s0.2,4.9,0.2,9.3v49.5
			c0,2.4-2.2,4.6-4.6,4.6h-18.3c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-5.6-20.7-18-20.7c-12.2,0-18,8.8-20.5,15.4v53.6
			c0,2.4-2.2,4.6-4.6,4.6H967c-2.4,0-4.6-2.2-4.6-4.6L962.4,126.5L962.4,126.5z"
          />
          <path
            fill="#FFFFFF"
            d="M1194.7,119.4c27.6,0,50,23.4,50,51c0,28-22.4,51.4-50,51.4c-27.5,0-50-23.4-50-51.4
			C1144.7,142.9,1167.2,119.4,1194.7,119.4z M1194.7,195.5c12.9,0,23.4-11.2,23.4-25.1c0-13.7-10.5-24.6-23.4-24.6
			c-13.2,0-23.4,11-23.4,24.6C1171.3,184.3,1181.5,195.5,1194.7,195.5z"
          />
          <path
            fill="#FFFFFF"
            d="M1264.7,126.5c0-2.7,2.2-4.6,4.6-4.6h20c2.4,0,4.6,1.9,4.6,4.6v48c0,12.9,6.6,21,17.8,21
			c10.2,0,18-9.5,19.5-18.3v-50.7c0-3.7,1.5-4.6,5.4-4.6h18.5c2.4,0,4.6,1.9,4.6,4.6v88.3c0,2.4-2.2,4.6-4.6,4.6h-9
			c-2.4,0-3.9-1.7-4.6-3.7l-2.9-8c-4.4,3.9-13.4,14.1-32.7,14.1c-30.2,0-41.2-23.2-41.2-48L1264.7,126.5L1264.7,126.5z"
          />
          <path
            fill="#FFFFFF"
            d="M1383.7,207.7l6.6-13.2c1-2.4,3.4-2.9,5.6-1.7c0,0,11,5.8,22.2,5.8c4.9,0,8.3-1.9,8.3-6.1
			c0-4.4-3.4-7.1-16.3-12.2c-18.8-7.3-27.6-17.3-27.6-32.2s11-28.8,35.8-28.8c14.4,0,24.9,4.1,30,7.6c2.2,1.5,3.4,4.1,2.2,6.6
			l-6.1,12.4c-1.2,2.2-3.9,2.4-5.8,1.7c0,0-11-5.1-20.2-5.1c-5.8,0-8.3,2.4-8.3,5.6c0,4.4,4.4,5.8,13.4,9.5
			c18.8,7.3,32.9,15.4,32.9,34.4c0,16.1-14.1,29.7-38.8,29.7c-16.1,0-27.3-5.1-32.4-9C1383.7,211.6,1382.7,209.6,1383.7,207.7z"
          />
        </g>
        <circle fill="#F4D461" cx="151.8" cy="170" r="148.1" />
        <g>
          <g>
            <circle fill="#063346" cx="151.8" cy="133.1" r="32.9" />
          </g>
          <path
            fill="#063346"
            d="M151.8,271.2c-56.6,0-102.9-45-105.1-101.1c-0.1-2.3,1.7-4.2,4-4.2h42.8c2.1,0,3.8,1.6,4,3.7
			c1.9,28.3,25.6,50.7,54.4,50.7s52.4-22.4,54.4-50.7c0.1-2.1,1.9-3.7,4-3.7H253c2.3,0,4.1,1.9,4,4.2
			C254.8,226.2,208.5,271.2,151.8,271.2z"
          />
        </g>
      </g>
    </symbol>

    <symbol id="logo-wordmark-dark" viewBox="0 0 1460 340">
      <g>
        <g>
          <path
            fill="#063346"
            d="M963.4,249.8c0-0.5,0.4-0.9,0.9-0.9h4.3c0.5,0,0.9,0.4,0.9,0.9v12.2h15v-12.2c0-0.5,0.4-0.9,0.9-0.9h4.2
			c0.5,0,0.9,0.4,0.9,0.9v30.4c0,0.5-0.4,0.9-0.9,0.9h-4.2c-0.5,0-0.9-0.4-0.9-0.9v-12.7h-15v12.7c0,0.5-0.4,0.9-0.9,0.9h-4.3
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
          <path
            fill="#063346"
            d="M1060.9,249.8c0-0.5,0.4-0.9,0.9-0.9h18.7c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-13.6v7.5h11.4
			c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-11.4v8.1h13.6c0.5,0,0.9,0.4,0.9,0.9v3.8c0,0.5-0.4,0.9-0.9,0.9h-18.7
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
          <path
            fill="#063346"
            d="M1145.8,279.9l14.3-31c0.1-0.3,0.4-0.5,0.8-0.5h0.5c0.5,0,0.6,0.2,0.8,0.5l14.2,31c0.3,0.6-0.1,1.2-0.8,1.2
			h-4c-0.7,0-1-0.3-1.3-0.9l-2.2-5h-13.7l-2.3,5c-0.2,0.5-0.6,0.9-1.3,0.9h-4C1145.9,281.1,1145.6,280.5,1145.8,279.9z
			 M1165.7,270.1l-4.6-10.1h-0.1l-4.5,10.1H1165.7z"
          />
          <path
            fill="#063346"
            d="M1242.6,249.8c0-0.5,0.4-0.9,0.9-0.9h4.2c0.5,0,0.9,0.4,0.9,0.9v25.8h11.7c0.5,0,0.9,0.4,0.9,0.9v3.8
			c0,0.5-0.4,0.9-0.9,0.9h-16.8c-0.5,0-0.9-0.4-0.9-0.9L1242.6,249.8L1242.6,249.8z"
          />
          <path
            fill="#063346"
            d="M1330.4,254.4h-7c-0.5,0-0.9-0.4-0.9-0.9v-3.8c0-0.5,0.4-0.9,0.9-0.9h20.1c0.5,0,0.9,0.4,0.9,0.9v3.8
			c0,0.5-0.4,0.9-0.9,0.9h-7v25.8c0,0.5-0.4,0.9-0.9,0.9h-4.3c-0.5,0-0.9-0.4-0.9-0.9L1330.4,254.4L1330.4,254.4z"
          />
          <path
            fill="#063346"
            d="M1411.2,249.8c0-0.5,0.4-0.9,0.9-0.9h4.3c0.5,0,0.9,0.4,0.9,0.9v12.2h15v-12.2c0-0.5,0.4-0.9,0.9-0.9h4.2
			c0.5,0,0.9,0.4,0.9,0.9v30.4c0,0.5-0.4,0.9-0.9,0.9h-4.2c-0.5,0-0.9-0.4-0.9-0.9v-12.7h-15v12.7c0,0.5-0.4,0.9-0.9,0.9h-4.3
			c-0.5,0-0.9-0.4-0.9-0.9V249.8z"
          />
        </g>
        <g>
          <path
            fill="#063346"
            d="M411.4,157.7c9.8,0,17.8,2.7,17.8,2.7c0.2-12.4-3.7-16.8-12.7-16.8s-21.7,1.9-27.3,3.7
			c-3.4,1-5.1-1.2-5.6-4.4l-2.2-12.7c-0.7-3.7,1.2-5.4,3.4-6.1c1.9-0.7,17.6-4.6,33.2-4.6c30.5,0,37.5,15.8,37.5,42.4v52.9
			c0,2.4-2.2,4.6-4.6,4.6H442c-1.7,0-2.9-0.7-4.1-3.4l-2.9-6.6c-5.6,5.1-14.6,12.4-29.8,12.4c-19.5,0-32.9-12.7-32.9-33.4
			C372.3,171.1,386.5,157.7,411.4,157.7z M411.8,201.1c7.8,0,15.4-6.6,16.8-9.5v-13.4c0,0-6.6-2.9-13.7-2.9
			c-9.5,0-15.8,5.4-15.8,13.2C399.2,195.8,404.3,201.1,411.8,201.1z"
          />
          <path
            fill="#063346"
            d="M483.5,126.5c0-2.7,2.2-4.6,4.6-4.6h9.3c2.2,0,3.4,1.2,4.1,2.9l3.4,9c3.7-3.9,15.6-14.4,34.4-14.4
			c31,0,43.4,23.2,43.4,48.5v46.8c0,2.4-2.2,4.6-4.6,4.6h-20c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.6-20.7-18.3-20.7
			c-12.7,0-20.5,8.8-22.9,15.4v53.6c0,3.4-1.5,4.6-6.1,4.6h-18c-2.4,0-4.6-2.2-4.6-4.6L483.5,126.5L483.5,126.5z"
          />
          <path
            fill="#063346"
            d="M652.5,119.4c27.6,0,50,23.4,50,51c0,28-22.4,51.4-50,51.4c-27.5,0-50-23.4-50-51.4
			C602.5,142.9,624.9,119.4,652.5,119.4z M652.5,195.5c12.9,0,23.4-11.2,23.4-25.1c0-13.7-10.5-24.6-23.4-24.6
			c-13.2,0-23.4,11-23.4,24.6C629.1,184.3,639.3,195.5,652.5,195.5z"
          />
          <path
            fill="#063346"
            d="M724.2,126.5c0-2.7,2.2-4.6,4.6-4.6h9.3c2.2,0,3.4,1.2,4.1,2.9l3.4,9c3.7-3.9,15.6-14.4,34.4-14.4
			c31,0,43.4,23.2,43.4,48.5v46.8c0,2.4-2.2,4.6-4.6,4.6h-20c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.6-20.7-18.3-20.7
			c-12.7,0-20.5,8.8-22.9,15.4v53.6c0,3.4-1.5,4.6-6.1,4.6h-18.1c-2.4,0-4.6-2.2-4.6-4.6L724.2,126.5L724.2,126.5z"
          />
          <path
            fill="#063346"
            d="M836.8,128.2c-1.5-3.2,0.5-6.3,4.1-6.3h21.9c2.2,0,3.4,1.2,4.1,2.7l24.6,55.1h0.2l24.4-55.1
			c1.2-2.4,2.9-2.7,5.8-2.7h19.5c3.9,0,5.8,3.2,4.1,6.3l-57.1,123.9c-0.7,1.5-2.2,2.9-4.1,2.9h-21c-3.7,0-5.8-3.2-4.1-6.6l17.1-35.9
			L836.8,128.2z"
          />
          <path
            fill="#063346"
            d="M962.4,126.5c0-2.7,2.2-4.6,4.6-4.6h9c2.2,0,3.7,1.2,4.1,2.7l3.4,9.3c4.1-4.6,15.4-14.4,32.7-14.4
			c13.9,0,23.7,5.4,31.7,15.1c4.1-4.4,16.8-15.1,34.4-15.1c31.2,0,42.7,23.2,42.7,48.8v46.6c0,2.4-1.9,4.6-4.6,4.6h-19.8
			c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-6.1-20.7-18.3-20.7c-14.1,0-20.5,10.2-20.5,10.2s0.2,4.9,0.2,9.3v49.5
			c0,2.4-2.2,4.6-4.6,4.6h-18.3c-2.7,0-4.6-2.2-4.6-4.6v-48.3c0-12.7-5.6-20.7-18-20.7c-12.2,0-18,8.8-20.5,15.4v53.6
			c0,2.4-2.2,4.6-4.6,4.6H967c-2.4,0-4.6-2.2-4.6-4.6L962.4,126.5L962.4,126.5z"
          />
          <path
            fill="#063346"
            d="M1194.7,119.4c27.6,0,50,23.4,50,51c0,28-22.4,51.4-50,51.4c-27.5,0-50-23.4-50-51.4
			C1144.7,142.9,1167.2,119.4,1194.7,119.4z M1194.7,195.5c12.9,0,23.4-11.2,23.4-25.1c0-13.7-10.5-24.6-23.4-24.6
			c-13.2,0-23.4,11-23.4,24.6C1171.3,184.3,1181.5,195.5,1194.7,195.5z"
          />
          <path
            fill="#063346"
            d="M1264.7,126.5c0-2.7,2.2-4.6,4.6-4.6h20c2.4,0,4.6,1.9,4.6,4.6v48c0,12.9,6.6,21,17.8,21
			c10.2,0,18-9.5,19.5-18.3v-50.7c0-3.7,1.5-4.6,5.4-4.6h18.5c2.4,0,4.6,1.9,4.6,4.6v88.3c0,2.4-2.2,4.6-4.6,4.6h-9
			c-2.4,0-3.9-1.7-4.6-3.7l-2.9-8c-4.4,3.9-13.4,14.1-32.7,14.1c-30.2,0-41.2-23.2-41.2-48L1264.7,126.5L1264.7,126.5z"
          />
          <path
            fill="#063346"
            d="M1383.7,207.7l6.6-13.2c1-2.4,3.4-2.9,5.6-1.7c0,0,11,5.8,22.2,5.8c4.9,0,8.3-1.9,8.3-6.1
			c0-4.4-3.4-7.1-16.3-12.2c-18.8-7.3-27.6-17.3-27.6-32.2s11-28.8,35.8-28.8c14.4,0,24.9,4.1,30,7.6c2.2,1.5,3.4,4.1,2.2,6.6
			l-6.1,12.4c-1.2,2.2-3.9,2.4-5.8,1.7c0,0-11-5.1-20.2-5.1c-5.8,0-8.3,2.4-8.3,5.6c0,4.4,4.4,5.8,13.4,9.5
			c18.8,7.3,32.9,15.4,32.9,34.4c0,16.1-14.1,29.7-38.8,29.7c-16.1,0-27.3-5.1-32.4-9C1383.7,211.6,1382.7,209.6,1383.7,207.7z"
          />
        </g>
        <circle fill="#F4D461" cx="151.8" cy="170" r="148.1" />
        <g>
          <g>
            <circle fill="#063346" cx="151.8" cy="133.1" r="32.9" />
          </g>
          <path
            fill="#063346"
            d="M151.8,271.2c-56.6,0-102.9-45-105.1-101.1c-0.1-2.3,1.7-4.2,4-4.2h42.8c2.1,0,3.8,1.6,4,3.7
			c1.9,28.3,25.6,50.7,54.4,50.7s52.4-22.4,54.4-50.7c0.1-2.1,1.9-3.7,4-3.7H253c2.3,0,4.1,1.9,4,4.2
			C254.8,226.2,208.5,271.2,151.8,271.2z"
          />
        </g>
      </g>
    </symbol>
  </svg>
);

export default SVGSprite;
