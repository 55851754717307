import React from "react";
import styled, { css } from "styled-components";

// Utilities
import * as mediaQuery from "../utilities/mediaQuery";

// Components
import Logo from "./Logo";

// Styled Components
const HeaderContainer = styled.header`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 3em 8vw;
  position: relative;
  width: 100%;
  z-index: 1;

  ${mediaQuery.queryBelow(
    "bigPhone",
    css`
      padding: 2em 8vw;
    `
  )}
`;

const Header = ({ className }) => {
  return (
    <HeaderContainer className={className}>
      <Logo className={className} />
    </HeaderContainer>
  );
};

export default Header;
