// Brand Colors
export const brand = "#FFD234"
export const black = "#080c17"
export const darkPoint = "#0A1840"
export const session1DarkPoint = "#001712"
export const session1Divider = "#E5E3DE"
// export const darkPoint = "#001712"
export const white = "#FFFFFF"
export const lightPoint = "#FCFAF5"
export const rangeSliderTrack = "#EFEDE6"
export const type = "#65726D"
// export const blackPoint = "#0F0C03"

// Video Specific
export const videoBG = "#0A1840";